/* React Player */

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;

}

/* Fading animation */

.mySlides {display: none;}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

.tbifade {
  -webkit-animation-name: tbifade;
  -webkit-animation-duration: 2s;
  animation-name: tbifade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {opacity: .3} 
  to {opacity: 1}
}

@-webkit-keyframes tbifade {
  0% {opacity: 1}
  50% {opacity: 1} 
  80% {opacity:0.7} 
  90% {opacity:0.5} 
  100% {opacity: 0}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes tbifade {
  0% {opacity: 1}
  50% {opacity: 1} 
  80% {opacity:0.7} 
  90% {opacity:0.5} 
  100% {opacity: 0}
}
